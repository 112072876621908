import React, { useState, useEffect } from 'react';
import { data } from '../Components/EventData';
import { useParams } from 'react-router-dom';
import Navbar from './Navbar';
import Footer2 from './Footer2';
import Footer3 from './Footer3';
import Footer4 from './Footer4';

const Display1 = () => {
  const [images, setImages] = useState([]);
  let { name } = useParams();

  useEffect(() => {
    // Filter data based on the name param
    const eventData = data.find((item) => item.name === name);
    if (eventData) {
      setImages(eventData.images);
    }
  }, [name]);

  return (
    <div className='bg-gray-100 min-h-screen'>
      {/* Navbar */}
      <Navbar />

      {/* Main Content */}
      <div className='container mx-auto py-8'>
        <h1 className='text-3xl font-bold text-center mb-8 text-gray-800 capitalize'>
          {name} Event Gallery
        </h1>

        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 px-4'>
          {images.map((item, key) => (
            <div
              key={key}
              className='relative overflow-hidden rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out'
            >
              {/* Image */}
              <img
                className='w-full h-64 object-cover transition-transform duration-300 ease-in-out hover:scale-105'
                src={item.img}
                 loading="lazy"
                alt={`event-${key}`}
              />

              {/* Overlay effect (optional) */}
              <div className='absolute inset-0 bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-0 hover:opacity-100 transition-opacity duration-300'>
                <div className='absolute bottom-0 left-0 p-4 text-white'>
                  <h3 className='text-lg font-semibold'>{name}</h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Footer */}
      <Footer4/>
    </div>
  );
};

export default Display1;
