import React from 'react'
import Carousel from './Carousel';
import Navbar from './Navbar';
import school from '../Assests/school-1.jpg'
import ground from '../Assests/school ground.jpg'
import Stats from '../Components/Stats';
import EnrollmentBanner from './EnrollmentBanner';
import Correspondent from '../Assests/Correspondent.jpg';
import Footer4 from './Footer4';
import Images from './Images';
import sixty from "../Assests/60yrs.png"
import CelebrationSection from './CelebrationSection';
import ExperienceSection from './ExperienceSection ';
import SectionWithBackground from './SectionWithBackground';
import children2 from '../Assests/Children-2.jpeg';
import MissionSection from './MissionSection';
import PrincipalMsg from './PrincipalMsg';
import sports from '../Assests/sports.webp'
import science from  '../Assests/science.webp'


const Home = () => {

  window.addEventListener('scroll',reveal)
  function reveal(){
   var reveals = document.querySelectorAll('.reveal')
 
   for(var i=0;i<reveals.length;i++)
   {
     var windowheight = window.innerHeight;
     var revealtop = reveals[i].getBoundingClientRect().top;
     var revealpoint =150;
 
     if(revealtop  < windowheight -revealpoint)
     {
          reveals[i].classList.add('active');
     }
     else{
       reveals[i].classList.remove('active');
     }
   }
  }


    let slides = [
        // "https://i.pinimg.com/originals/51/82/ac/5182ac536727d576c78a9320ac62de30.jpg",
        // "https://wallpapercave.com/wp/wp3386769.jpg",
        // "https://wallpaperaccess.com/full/809523.jpg",
        // "https://getwallpapers.com/wallpaper/full/5/c/0/606489.jpg",
        school,
        ground,
        school,
        ground
      ];

  return (
    <div>
  
  
      <Navbar/>
       <div className=" h-[30%]">
         <Carousel slides={slides} />
        </div>

  {/* end of carousel  */}
  
   <CelebrationSection/>
   
   <PrincipalMsg/>
  
   <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto flex flex-wrap">
    <div class="flex w-full flex-wrap">
      {/* <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900 lg:w-1/3 lg:mb-0 mb-4">Experience & Discover The SHHS</h1> */}
      <h2 className="text-center text-2xl sm:text-3xl lg:text-4xl font-bold text-gray-800 mb-8">
        Experience & Discover The SHHS
      </h2>
      {/* <p class="lg:pl-6 lg:w-2/3 mx-auto leading-relaxed text-base">Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably haven't heard of them man bun deep jianbing selfies heirloom.</p> */}
    </div>
    <div class="flex flex-wrap md:-m-2 -m-1">
      <div class="flex flex-wrap w-1/2">
        <div class="md:p-2 p-1 w-1/2">
          <img alt="gallery" class="w-full object-cover h-full object-center block" src={children2} />
        </div>
        <div class="md:p-2 p-1 w-1/2">
          <img alt="gallery" class="w-full object-cover h-full object-center block" src={science} />
        </div>
        <div class="md:p-2 p-1 w-full">
          <img alt="gallery" class="w-full h-full object-cover object-center block" src={school} />
        </div>
      </div>
      <div class="flex flex-wrap w-1/2">
        <div class="md:p-2 p-1 w-full">
          <img alt="gallery" class="w-full h-full object-cover object-center block" src={sports} />
        </div>
        <div class="md:p-2 p-1 w-1/2">
          <img alt="gallery" class="w-full object-cover h-full object-center block" src={children2} />
        </div>
        <div class="md:p-2 p-1 w-1/2">
          <img alt="gallery" class="w-full object-cover h-full object-center block" src={children2} />
        </div>
      </div>
    </div>
  </div>
</section>
  
   

   {/* <div className="py-8 flex flex-col items-center justify-center">
      <h1 className="text-3xl md:text-4xl font-semibold text-center text-gray-800 mb-6">
        Leadership with Vision and Dedication
      </h1>
      <Images />
  </div> */}

  {/* <div className=''>
  <MissionSection/>
  </div> */}
 

 <div className='py-16'>
 
 <div className="flex font-poppins flex-col md:flex-row w-3/4 mx-auto">
{/* Our Goal */}
<div className="flex-1 bg-yellow-600 text-white text-center p-6 ">
<h2 className="text-2xl font-bold">OUR GOAL</h2>
<p className="mt-4 text-lg font-medium">
 Service Through Excellence, by following our school motto "Truth and Charity".
</p>
</div>

{/* Our Vision */}
<div className="flex-1 bg-green-700 text-white text-center p-6">
<h2 className="text-2xl font-bold">OUR VISION</h2>
<p className="mt-4 text-lg font-medium">
 To impart all-round quality education and holistic development through active participation and competition.
</p>
</div>

{/* Our Mission */}
<div className="flex-1 bg-red-700 text-white text-center p-6">
<h2 className="text-2xl font-bold">OUR MISSION</h2>
<p className="mt-4 text-lg font-medium">
 To build a strong TEAM of Management, Staff, parents, and students who work in a true spirit of collaboration and co-operation.
</p>
</div>
</div>

 </div>



  <div>
    <Stats/>
    <EnrollmentBanner/>
   </div>

   <Footer4/>
    </div>
  )
}

export default Home
