import React, { useEffect } from 'react';
import sixty from "../Assests/60years.png";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles globally

const CelebrationSection = () => {
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 1000, // Default animation duration
      easing: "ease-in-out", // Default easing for animations
      delay:10,
      mirror: true, // Whether elements animate out while scrolling past them
      once: false, // Whether animation should happen only once
    });

    // Refresh AOS to apply animations
    AOS.refresh();
  }, []);

  return (
    <div>
      <section class="text-gray-600 body-font py-16 bg-gray-100">

      <h2 className="text-4xl md:text-6xl text-center text-blue-900 font-bold uppercase mb-8">
        Welcome to Sacred Heart High School
      </h2>
        <div className="container mx-auto flex px-5 py-16  items-center justify-center flex-col">
            <img className="flex-shrink-0 rounded-lg w-64 h-64 object-cover object-center sm:mb-0 mb-4" data-aos="fade-up" alt="60 Years Celebration" loading='lazy' src={sixty}/>
            <div className="text-center lg:w-2/3 w-full">
            <h1 className="title-font sm:text-lg md:text-xl lg:text-2xl mb-4 font-medium py-8 px-4 sm:px-8 lg:px-16 text-gray-900" data-aos="fade-up">
                We proudly mark six decades of serving our community, inspired by the Sacred Heart.
                </h1>
            </div>
        </div>
    </section>

    </div>
  );
};

export default CelebrationSection;
