import React, { Suspense,useEffect }  from 'react';
import Home from './Components/Home';
import AboutUs from './Components/AboutUs';
import Contactus from './Components/Contactus';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Events from './Components/Events';
import OnlineAdmissions from './Components/OnlineAdmissions';
import Display1 from './Components/Display1';
import FeeData from './Components/FeeData';
import AdmissionProcedure from './Components/AdmissionProcedure';
import Calendar from './Components/Calendar';
import Activities from './Components/Activities';
import Login from './Components/Login';
import DashboardLayout from './Components/DashboardLayout';
import PrincipalMsg from './Components/PrincipalMsg';
import CorrespondentMsg from './Components/CorrespondentMsg';
import AOS from 'aos'
import OurMission from './Components/OurMission';
import SchoolCabinet from './Components/SchoolCabinet';


const App = () => {
  useEffect( () =>{
    AOS.init({duration:100,delay:"10"})
  },[])
  return (
   
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/aboutus' element={<AboutUs />} />
          <Route exact path='/events' element={<Events />} />
          <Route exact path='/contactus' element={<Contactus />} />
          <Route exact path='/admissions' element={<OnlineAdmissions />} />
          <Route exact path='/events/:name' element={<Display1 />} />
          <Route exact path='/FeeStructure' element={<FeeData />} />
          <Route exact path='/AdmissionProcedure' element={<AdmissionProcedure />} />
          <Route exact path='/Calendar' element={<Calendar />} />
          <Route exact path='/activities' element={<Activities />} />
          <Route exact path='/ourmission' element={<OurMission />} />
          <Route exact path='/schoolcabinet' element={<SchoolCabinet />} />
          <Route exact path='/correspondentmsg' element={<CorrespondentMsg />} />
          <Route exact path='/login' element={<Login />} />
        </Routes>
      </BrowserRouter>
   
  );
};

export default App;
