import React from 'react';
import { Disclosure } from '@headlessui/react';
import Header from './Header';
import { Bars3Icon, XMarkIcon, ChevronDownIcon } from '@heroicons/react/24/outline';

const navigation = [
  { name: 'Home', href: '/', current: true },
  {
    name: 'About Us',
    href: '#',
    current: false,
    dropdown: [
      { name: 'About us', href: '/aboutus' },
      { name: 'Correspondent Message', href: '/correspondentmsg' },
      { name: 'Our Mission', href: '/ourmission' },
      { name: 'School Cabinet', href: '/schoolcabinet' },
      { name: 'School Groups', href: '/aboutus' }
    ],
  },,
  { name: 'Events', href: '/events', current: false },
  { name: 'Contact Us', href: '/contactus', current: false },
  {
    name: 'Online Admissions',
    href: '#',
    current: false,
    dropdown: [
      { name: 'Admission Process', href: '/AdmissionProcedure' },
      { name: 'Fee Structure', href: '/FeeStructure' },
      { name: 'Current Year Admissions', href: '/admissions' },
    ],
  },
  // { name: 'Calendar', href: '/Calendar', current: false },
  { name: 'Activities', href: '/activities', current: false },
  { name: 'Login', href: '/login', current: false },
];
 
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Navbar = () => {
  return (
    <div>
      <Header />

      <Disclosure as="nav" className="bg-gray-800 py-2 text-white font-bold">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-6lg px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4 font-poppins">
                      {navigation.map((item) =>
                        !item.dropdown ? (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? 'text-white'
                                : 'text-white hover:text-white hover:underline underline-offset-',
                              'rounded-md px-3 py-2 font-medium'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </a>
                        ) : (
                          <div key={item.name} className="relative">
                            <Disclosure>
                              {({ open }) => (
                                <>
                                  <Disclosure.Button className="inline-flex items-center rounded-md px-3 py-2 text-white hover:text-white hover:underline">
                                    {item.name}
                                    <ChevronDownIcon
                                      className={`ml-2 h-5 w-5 transition-transform duration-200 ${
                                        open ? 'rotate-180' : 'rotate-0'
                                      }`}
                                    />
                                  </Disclosure.Button>
                                  <Disclosure.Panel className="absolute z-10 mt-2 w-48 bg-white shadow-lg rounded-md py-1">
                                    {item.dropdown.map((subitem) => (
                                      <a
                                        key={subitem.name}
                                        href={subitem.href}
                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                      >
                                        {subitem.name}
                                      </a>
                                    ))}
                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                <ul className="flex flex-col justify-between">
                  {navigation.map((item) => (
                    <li key={item.name} className="py-2">
                      {!item.dropdown ? (
                        <a
                          href={item.href}
                          className="text-white hover:underline underline-offset-4 text-base decoration-sky-500"
                        >
                          {item.name}
                        </a>
                      ) : (
                        <Disclosure>
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="w-full flex justify-between items-center text-white hover:underline underline-offset-4 text-base decoration-sky-500">
                                {item.name}
                                <ChevronDownIcon
                                  className={`ml-2 h-5 w-5 transition-transform duration-200 ${
                                    open ? 'rotate-180' : 'rotate-0'
                                  }`}
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel className="pl-4">
                                {item.dropdown.map((subitem) => (
                                  <a
                                    key={subitem.name}
                                    href={subitem.href}
                                    className="block py-2 text-white hover:underline"
                                  >
                                    {subitem.name}
                                  </a>
                                ))}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default Navbar;
