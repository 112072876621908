import React from 'react'
import Correspondent from '../Assests/Correspondent.jpg';
import Navbar from './Navbar';
import Footer4 from './Footer4';
const CorrespondentMsg = () => {
  return (
    <div>
    
    <Navbar/>
     <div>
      <h2 className="text-4xl p-4 mt-8 text-blue-900 font-bold uppercase">
           welcome to sacred heart high school
     </h2>
     


      <div className="flex flex-col md:flex-row items-start md:space-x-8 p-4 md:p-8 mx-auto text-justify">
            {/* Image Section */}
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <div className="rounded-lg  border-t-8 border-l-8  p-1">

                <img
                src={Correspondent} // Add your image source
                alt="Principal"
                className="w-full h-auto rounded-lg shadow-lg"
                />
                </div>
            </div>

            {/* Text Section */}
            <div className="w-full md:w-2/3 text-md font-poppins text-gray-600">
                <p>
                Warm welcome to Sacred Heart High School, which has completed 60 years. <br/>
                Since its inception in 1964, our school has been excelling, all round with   efficient and committed leaders and teachers. 
                </p>
                <br />
                <p>
                Our educational service aims to shape the students to  excel academically, intellectually competent, morally 
                and emotionally sound, physically strong and spiritually imbibed with a deep sense of noble values and God centered.
                </p>
                <br />
                <p>
                Our school campus has its natural ambiance and unique beauty, where one feels welcomed and attracted. Yes, you can breathe fresh air and feel healthy. 
                 <br/>
            The school management, the teachers, and the supportive staff are committed to creating an atmosphere in which the zest for learning thrives and students are trained to excel. 
                <br/>
            The school aims at integral formation of the students and help them to grow as integrated persons.

                </p>
                <br />
                <p>
                The programs are students centered, and students are encouraged to build a healthy environment and gear 
                towards building a healthy society. Hence, they are trained to be harmonious with one another, nature and the divine grace/energy. They are motivated to think creatively and dream a big. 
                </p>
                <br />
                <p>
                Besides rigorous intellectual and academic programs, the school has marked its name in all spheres such as art and craft, dance singing sports events, NCC,
                psychological counselling, so on, and so forth. Our campus has an best infrastructural facility that provides to  conduct various activities during club periods 
                as well as co-curricular, inter-school and state level competitions.Our students are expected to participate actively and to take advantage of the many opportunities 
                offered, so they can develop into self-confident, good self-esteem and be responsible and compassionate human beings. 

                </p>
                <p>
                <br/>
                We wish and help our students to be transformed individuals and, in turn, to transform society. 
                Every child is a gift to our school. We believe and value their giftedness and their ability to learn and excel. 
                <br/>
            Let our school Motto truth and charity make our students stand for the truth and be always considerate and practice  charity, respect, and human dignity.
                </p>
                <br />
                <p>
                Yours in Education,
                <br />
                <strong>SR. Arogya Marry,</strong>
                <br />
                Correspondent
                </p>
            </div>
            </div>
            
     </div>
     <Footer4/>
    </div>
  )
}

export default CorrespondentMsg
