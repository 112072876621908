import React from "react";
import logo from '../Assests/logo.png'

const Footer4 = () => {
  const footerData = {
    schoolInfo: {
      title: "SACRED HEART HIGH SCHOOL",
      description:
        `at Godavarikhani, Telangana was established in the year 1964 by margaretta with the Motto “Truth and Charity” run by Arogya Marry and Sr.Maria Goretti.`,
      socialLinks: [
        { icon: "fab fa-facebook-f", link: "#", color: "bg-blue-600" },
        { icon: "fab fa-instagram", link: "#", color: "bg-pink-500" },
        { icon: "fab fa-pinterest", link: "#", color: "bg-red-600" },
        { icon: "fab fa-youtube", link: "#", color: "bg-green-500" },
      ],
    },
    contactInfo: [
      { icon: "fas fa-phone", text: "+91 1235789" },
      { icon: "fas fa-envelope", text: "SacredHeartschool@gmail.com" },
      { icon: "fas fa-map-marker-alt", text: "Godavarikhani, Telangana" },
      { icon: "fas fa-clock", text: "Mon - Fri (9am - 4pm), Sun CLOSED" },
    ],
  
    quicklinks : [
        {
          name : "Admissions",
          namelink :"/admissions"
        },
        {
        name : "Events",
        namelink :"/events"
        },
        {
        name : "About Us",
        namelink :"/about-us"
        },
        {
        name : "Calendar",
        namelink :"/calendar"
        },
        {
        name : "Activities",
        namelink :"/activities"
        },
    ],
    
    newsletter: {
      title: "Newsletter",
      description: "Subscribe to our Newsletter right now to be updated.",
    },
  };

  return (
<div>

<div className="lg:-mb-32 mt-16 p-4 hidden md:block">
  <div className="flex flex-col md:flex-row md:w-3/4 sm:w-full mx-auto font-poppins">
    {/* Our Goal */}
    <div className="flex-1 bg-blue-600 text-white text-center p-6">
      <h2 className="text-2xl font-bold">OUR GOAL</h2>
      <p className="mt-4 text-lg font-medium">
        Service Through Excellence, by following our school motto "Truth and Charity".
      </p>
    </div>

    {/* Our Vision */}
    <div className="flex-1 bg-blue-600 text-white text-center p-6">
      <h2 className="text-2xl font-bold">OUR VISION</h2>
      <p className="mt-4 text-lg font-medium">
        To impart all-round quality education and holistic development through active participation and competition.
      </p>
    </div>

    {/* Our Mission */}
    <div className="flex-1 bg-blue-600 text-white text-center p-6">
      <h2 className="text-2xl font-bold">OUR MISSION</h2>
      <p className="mt-4 text-lg font-medium">
        To build a strong TEAM of Management, Staff, parents, and students who work in a true spirit of collaboration and co-operation.
      </p>
    </div>
  </div>
</div>



    <footer className="bg-gray-800  mt-4 text-white py-10">

      <div className="max-w-7xl lg:pt-32  mx-auto px-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
         
        {/* School Info Section */}
        <div className="font-poppins">
          <img
            src="your-logo-url" // Add the actual logo URL here
            alt="Sacred Heart High School"
            className="mb-4"
          />
          <p className="font-semibold mb-2 text-blue-600">{footerData.schoolInfo.title}</p>
          <p className="text-sm">{footerData.schoolInfo.description}</p>
          <div className="flex space-x-3 mt-4">
            {footerData.schoolInfo.socialLinks.map((link, index) => (
              <a
                key={index}
                href={link.link}
                className={`${link.color} p-2 rounded-full`}
              >
                <i className={link.icon}></i>
              </a>
            ))}
          </div>
        </div>

        {/* Contact Section */}
        <div className="font-poppins">
          <h3 className="text-xl font-semibold mb-4 text-blue-600">Contact Us</h3>
          <div className="text-sm">
            {footerData.contactInfo.map((item, index) => (
              <p className="mb-2" key={index}>
                <i className={`${item.icon} mr-2`}></i> {item.text}
              </p>
            ))}
          </div>
        </div>

        {/* Branches Section */}
        <div>
          <h3 className="text-xl font-semibold mb-4 text-blue-600">Quick Links</h3>
          <ul className="text-md space-y-2  font-poppins">
            {footerData.quicklinks.map((branch, index) => (
              <li key={index} >
                <i className="fas fa-caret-right mr-2"></i> <a href={branch.namelink} >{branch.name}</a>
              </li>
            ))}
          </ul>
        </div>

        <div>
          <h3 className="text-xl font-semibold mb-4 text-blue-600">Quick Links</h3>
          <ul className="text-md space-y-2  font-poppins">
            {footerData.quicklinks.map((branch, index) => (
              <li key={index} >
                <i className="fas fa-caret-right mr-2"></i> <a href={branch.namelink} >{branch.name}</a>
              </li>
            ))}
          </ul>
        </div>

        {/* Newsletter Section */}
       
      </div>
      {/* Footer Bottom */}
      <div className="text-center text-sm text-white mt-10">
        © 2024, All Rights Reserved. Design by{" "}
        <a href="#" className="text-blue-600 hover:text-green-300 underline">
          Sacred Heart High School
        </a>
      </div>
    </footer>
    </div>
  );
};

export default Footer4;
