import React from "react";

const SectionWithBackground = ({
  title,
  backgroundImage,
  overlayColor = "bg-black",
  overlayOpacity = "bg-opacity-50",
  textColor = "text-white",
  height = "h-[300px]",
}) => {
  return (
    <section className={`relative w-full ${height}`}>
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      >
        <div className={`absolute inset-0 ${overlayColor} ${overlayOpacity}`}></div> {/* Overlay */}
      </div>

      {/* Text Content */}
      <div className="relative z-10 flex items-center justify-center h-full">
        <h1 className={`font-bold text-center ${textColor} text-4xl md:text-5xl lg:text-6xl`}>
          {title}
        </h1>
      </div>
    </section>
  );
};

export default SectionWithBackground;
