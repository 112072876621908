import React from 'react'
import Navbar from './Navbar'
import calendar from '../Assests/calendar.jpg'
import Footer4 from './Footer4'

const Calendar = () => {
  return (
    <div>
        <Navbar/>
        <div className='App-header'>
        <h2 className='text-5xl font-bold'>SCHOOL CIRCULARS</h2>
      </div>
        <div className="container mx-auto my-8 px-4 md:px-0">

            <img src={calendar} alt='SCHOOL CALENDAR' />

        </div>
      <Footer4/>
    </div>
  )
}

export default Calendar
