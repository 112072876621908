import React from 'react'
import mary from '../Assests/about-main.png'
import Navbar from './Navbar'
import Footer2 from './Footer2'
import Footer3 from './Footer3'
import Footer4 from './Footer4'

const AboutUs = () => {
  return (
    <div>
      <Navbar/>
   
    <div className='container mx-auto px-4 md:px-24  py-6 font-poppins'>
      
      

      <div className="mx-auto px-4 py-6">
      <h2 className="text-3xl font-semibold text-black-700 mb-6 text-center">
        ABOUT US
      </h2>

      <div className="flex flex-col md:flex-row items-start md:space-x-8 text-justify">
        {/* Image Section */}
        <div className="w-full md:w-1/3 mb-6 md:mb-0">
          <img
            src={mary} // Replace with your image path
            alt="Historical Image"
            className="w-full h-auto rounded-lg shadow-lg"
          />
        </div>

        {/* Text Section */}
        <div className="w-full md:w-2/3 text-md text-gray-600">
          <h3 className="text-2xl md:text-4xl font-bold text-blue-900 mb-4">
            A HISTORICAL PERSPECTIVE
          </h3>
          <p>
            The Franciscan Missionaries of Mary's involvement in education in India dates back to
            the foundation days of the Institute in 1877, in the Nilgiris. The idea of mass
            education, for girls of all classes and castes was alien to the ethos of Indian society
            of the 19th century. However, the efforts of missionaries to bring the benefits of
            education within the reach of the poorer sections found acceptance in the eyes of
            several reformers like Raja Ram Mohan Roy and Dayananda Saraswathi, who in turn began to
            advocate formal education for the masses irrespective of caste, creed and gender.
          </p>
          <br />
          <p>
            It was in this context that our Foundress, Blessed Mary of the Passion encouraged her
            sisters to set up schools almost wherever they went in India. She ardently believed that
            education alone could enable Women to take their rightful place in the family and
            society as equal and respected partners of men. Among the 100s of FMM Institutions,
            Sacred Heart High School was the last school started by Mother Mary of the Passion.
          </p>
          <br />
          <p>
            The FMM nuns’ persuasion to send their children to school and cajoling them not to deny
            their children the benefits of education, bore fruit.
          </p>
          <br />
          <p>
            The school started functioning in January 1904 with just 60 students in Tamil medium and
            50 in English Medium. In due course, Sacred Heart High School achieved name and fame
            and was recognized in the year 1925. In 2003 the school celebrated its Centenary with a
            yearlong celebration on 26th Jan, 2003 and culminated with a Holy Mass and Valedictory
            Public Function in January, 2004.
          </p>
          <br />
          <p>
            Sacred Heart High School is a minority Institution run by the Franciscan Missionaries
            of Mary who belong to the Catholic church. The school stands for character formation,
            academic excellence and the development of mind, soul and body.
          </p>
        </div>
      </div>
      </div>

     
      
      <div className='mx-auto text-justify p-6 bg-orange-100 '>
   <h2 className='text-blue-900 font-semibold text-4xl my-4'>SCHOOL MOTTO</h2>
     <p className='text-md leading-relaxed'>
     The motto of the school is “To Love Through Truth”.
     This ideal is set before the pupils, who are trained to face the truth at all times,
     to live in sincerity and honesty. Thus, their genuineness will lead them to love all
     and to be loved by all. The teacher-pupil relationship which is fostered in our school is one of love, 
     kindness, firmness, justice, sympathy and respect.</p>
   </div>


{/* <div className='pt-4'>
     <h2 className='text-blue-700 text-4xl  my-4'>SCHOOL ANTHEM</h2>
     <div className="   min-h-screen flex flex-col">
      <div className="max-w-xl text-start">
        <h1 className="text-2xl font-bold mb-4">Hail! Hail! Thee O Sacred Heart</h1>
        <p className="text-lg mb-6">Lead us ever on your right path</p>
        
        <div className="mb-8">
          <p className="text-lg mt-2">
            Sacred Heart Oh! My Pride<br />
            My Pride May your tribe increase Ever more (2)
          </p>
        </div>
        
        <p className="text-lg mb-6">
          All I have you gave me<br />
          All I learnt you taught me<br />
          All I give you is my love<br />
          To you ever my alma mater.
        </p>
        
        <div className="mb-8">
          <p className="text-lg mt-2">
            Sacred Heart (2)
          </p>
        </div>
        
        <p className="text-lg mb-6">
          Charity and Truth as our motto<br />
          We bear in our heart (2)
        </p>
        
        <p className="text-lg">
          Let honesty reign in our life<br />
          Not for vain, not for gain<br />
          Oh! Sacred Heart.
        </p>
      </div>
      </div>
    </div> */}


    <div className=" text-justify mx-auto mt-8 p-6 ">
      <h1 className="text-3xl font-semibold mb-4 text-blue-900 uppercase"> Rules</h1>
      <ul className="list-disc ml-6 space-y-2 text-md">
        <li>Punctuality is the primary requisite of the school. Students must be in the school premises by 8:45 a.m.</li>
        <li>No absentee or late arrival after Assembly may be admitted to class without the signature of the Principal or Sister in charge on the absence/Attendance Record page of the School Calendar.</li>
        <li>While the school will take every precaution to ensure the safety and protection of its students within the school premises during the school hours, the school authority will not hold themselves responsible for any untoward incident beyond their control.</li>
        <li>The students will be promoted to the next class only when there is required attendance and academic achievement. The principal's decision on the matter of promotion is final.</li>
        <li>Students suffering from contagious diseases will not be permitted to attend the school.</li>
        <li>Students should bring this handbook every day to the school and note down the homework.</li>
        <li>No student will be allowed to go out of the school premises during class hours, unless the parents take permission from the principal.</li>
        <li>Pupils are responsible to their school authorities for their conduct within and outside the school.</li>
        <li>Any damage caused to the school property will have to be made good at the expense of the party concerned.</li>
        <li>
          <strong>"Cleanliness is next to Godliness".</strong> Personal cleanliness and hygiene are greatly recommended to all. The students should be particularly careful not to throw any papers, seeds, etc., anywhere in the school premises. They should use the baskets provided for this purpose.
        </li>
        <li>The full school uniform must be worn daily unless instructed otherwise.</li>
        <li>
          Under no circumstances will any student be allowed to wear any ornaments or put mehendi to school. Finger-nails must not be kept long or painted, and hair-style should not be done by either boys or girls. Management is not responsible if the students lose their costly belongings.
        </li>
        <li>Silence must be observed in the corridors and on the stairs during class hours. There should be <strong>NO TALKING AT THE CHANGE OF PERIOD.</strong> Silence must be observed outside the principal's office. Running, playing, or shouting inside of the school building is never allowed.</li>
        <li>
          Before every Assembly, from the first warning of the bell, there must be perfect silence in the school building and on the premises. The children will line up in silence for the Assembly. The prayer begins at the second bell. When the students go for Games, they must walk in silence and in a single line. They should always keep to the left.
        </li>
        <li>
          Any communication (request or complaint) made by the parents should be addressed to the Principal, and not to the class teacher, and all correspondence from the school to the parents or guardians must go through the Principal.
        </li>
        <li>No student or staff member will be called to the phone during school hours. In case of urgency, however, messages received on the phone will be conveyed to them.</li>
      </ul>
    </div>


    <div className="  text-justify mx-auto p-6 bg-orange-100">
      <h1 className="text-3xl font-semibold mb-4 text-blue-900 uppercase"> Disciplinary Action :</h1>
      <ul className="list-disc ml-6 space-y-2 text-md">
      <li>
      The school Principal reserves the right to suspend or dismiss the students whose conduct or academic progress is not satisfactory. Absence on the opening and closing days of a term before and after special holidays like Christmas, Dipawali, Sankranthi, Dussehra, etc. will not be tolerated and a fine will be levied from the offenders.        </li>
    <li>Pupils who expect to return late to their homes are responsible for informing their parents about it in advance.</li>  
      <li>During the class hours children must not move about the premises. d. Students of Std. I to V celebrating Birthdays are allowed to wear colour dress. When celebrating birthdays Instead of giving sweets, (students may preferably) donate books for the School library. Any communication (request or complaint) made by the parents should be addressed to the Principal, and not to the class teacher, and all correspondence from the school to the parents or guardians must go through the Principal.
        </li>
      </ul>
    </div>




    <div className="  text-justify mx-auto  p-6 ">
      <h1 className="text-3xl font-semibold mb-4 text-blue-900 uppercase"> Leave of Absence </h1>
      <ul className="list-disc ml-6 space-y-2 text-md">
      <li>
      Repeated absence for feasts, wedding etc. renders the pupil liable for dismissal 
        </li>
    <li>Repeated absence without leave, or unexplained absence for more than ten consecutive days, renders the students liable to being struck off the rolls.
        </li>  
      <li>Parents are requested not to ask their children to come away from school before the school leaves except in case of real necessity in which case permission should be obtained previously in writing.
        </li>
        <li>It may be noted that children will not be sent home even in an emergency with anyone who might come for them during school hours without the parents or Guardian with a letter from the parents.
        </li>  
      <li>Any pupil desiring to be exempted from games must produce a Doctor's certificate to the effect that he/she is physically unfit to take part therein. Those pupils availing themselves of this exemption, must remain with others.
        </li>
      </ul>
    </div>

   
    <div className="  text-justify mx-auto  p-6 bg-orange-100">
      <h1 className="text-3xl font-semibold mb-4 text-blue-900 uppercase"> Rules for Admission</h1>
      <ul className="list-disc ml-6 space-y-2 text-md">
      <li>
      Only those who have completed 5 by 31-08-2017 are eligible for Std. I.    </li>
    <li>According to-the Government regulations currently in force only pupils who have completed at least five
         years by 31st August 2017 of the Scholastic year may be admitted to Std.l. It is therefore essential that 
         the Municipal Birth Certificate be submitted in original at the time of admission in support of the declaration 
         regarding date of birth. A corresponding scale of age is fixed for succeeding standards.
        </li>  
      
      </ul>
    </div>



    <div className="  text-justify mx-auto  p-6 ">
      <h1 className="text-3xl font-semibold mb-4 text-blue-900 uppercase"> WITHDRAWAL </h1>
      <ul className="list-disc ml-6 space-y-2 text-md">
      <li>
      A month's notice is to be given before the withdrawal of a pupil, else a fine will be charged. Such notice should be given in writing by the
       person responsible for the pupil and not by the pupil. Those who leave in April, must pay their fees for the month of May.
        </li>
    <li>School fees are due as long as the name of the pupil is on the Register. You are requested to notify the office on time 
        when the student leaves the school and also the fees will have to be paid till the name is withdrawn.
        </li>  
      <li>The Leaving Certificate without which a pupil cannot join any other school, shall be refused to those who have not paid their fees and other dues.
        </li>
        <li>Pupils who desire to discontinue studies should take their Leaving Certificate immediately. A fine will be charged if the certificate is taken
             after one year. And if a duplicate is applied for, the prescribed procedure must be followed.
        </li>  
      
      </ul>
    </div>
    </div>

    <Footer4/>
    </div>
  )
}

export default AboutUs
