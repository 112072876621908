import React from 'react';
import Navbar from './Navbar';
import children from '../Assests/Childrensday-wallpaper.jpeg';
import christmas from '../Assests/Christmas-wallpaper.jpeg';
import cermony from '../Assests/investiture-wallpaper.jpeg';
import { Link } from 'react-router-dom';
import Footer4 from './Footer4';

const wallpaper = [
  { name: 'ChildrensDay', img: children },
  { name: 'ChristmasDay', img: christmas },
  { name: 'InvestitureCeremony', img: cermony },
  { name: 'ChildrensDay', img: children },
  { name: 'ChristmasDay', img: christmas },
  { name: 'InvestitureCeremony', img: cermony },
];

const Events = () => {
  return (
    <div>
      <Navbar />

      <div className="container mx-auto  p-5">
        <h1 className="text-3xl font-bold  text-center mb-10"> School Events</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {wallpaper.map((item, key) => (
            <div
              key={key}
              className="bg-white rounded-xl shadow-lg overflow-hidden transform transition duration-500 hover:scale-105 hover:shadow-2xl hover:bg-indigo-50"
            >
              {/* Image with Hover Animation */}
              <div className="h-64 overflow-hidden relative">
                <img
                  alt={item.name}
                  className="object-cover object-center w-full h-full transition duration-500 ease-in-out transform hover:scale-110"
                  src={item.img}
                />
              </div>

              {/* Title and Button */}
              <div className="p-6 flex flex-col items-center">
                {/* Title */}
                <h2 className="text-xl font-semibold text-gray-800 mb-4 uppercase tracking-wider text-center">
                  {item.name}
                </h2>
                
                {/* See More Button */}
                <Link to={'/events/' + item.name} className="w-full">
                  <button className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-6 rounded-full shadow-lg hover:shadow-xl transition duration-300 ease-in-out w-full text-center">
                    See more
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="w-5 h-5 ml-2 inline-block"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer4/>
    </div>
  );
};

export default Events;
